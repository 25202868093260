<template>
  <div class="main-wrap">
    <!-- main -->
    <main>
      <div class="main-section">
        <div class="container h-100 small">
          <router-link to="/" class="logo">
            <img :src="require('@/assets/img/logo.png')" alt="" />
          </router-link>
          <div class="data-show">
            <div class="top">
              <div class="ring">
                <svg
                  class="radial-progress"
                  :data-percentage="publisherData.matchedPercentage"
                  viewBox="0 0 80 80"
                >
                  <circle class="incomplete" cx="40" cy="40" r="35"></circle>
                  <circle
                    class="complete"
                    cx="40"
                    cy="40"
                    r="35"
                    :style="completeCircleStyle"
                  ></circle>
                  <text
                    :class="$style.matchedCount"
                    x="50%"
                    y="57%"
                    transform="matrix(0, 1, -1, 0, 80, 0)"
                  >
                    {{ Math.round(publisherData.matchedPercentage) }}%
                  </text>
                </svg>
              </div>
              <h2>
                {{ publisherData.matchedCodesCount }} of these tracks <br />
                have results
              </h2>
            </div>
            <button
              :disabled="initCheckoutStatusPending"
              @click.prevent="initCheckout"
              class="button default-button"
            >
              {{
                initCheckoutStatusPending
                  ? "Processing"
                  : "Buy and Download these results"
              }}
            </button>
          </div>
          <Footer />
        </div>
      </div>
    </main>
    <!-- main end -->
  </div>
</template>

<script>
import { publisherDataComputedState } from "@/views/apps/services/publisherDataService";
import { getStripe } from "@/services/stripeService";
import { API_STATUS } from "@/constants/apiStatus";
import { createCheckoutSession } from "@/api/stripeApi";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import Footer from "../Footer.vue";
const { IDLE, PENDING, SUCCESS, ERROR } = API_STATUS;
export default {
  components: {
    Footer,
  },
  data() {
    return {
      initCheckoutStatus: IDLE,
    };
  },
  computed: {
    ...publisherDataComputedState,
    ...apiStatusComputedFactory("initCheckoutStatus"),
    completeCircleStyle() {
      const radius = 35;
      const circumference = 2 * Math.PI * radius;
      const strokeDashOffset =
        circumference -
        (this.publisherData.matchedPercentage * circumference) / 100;

      return {
        "stroke-dashoffset": strokeDashOffset,
      };
    },
  },
  methods: {
    async initCheckout() {
      try {
        const stripe = await this.stripePromise;
        this.initCheckoutStatus = PENDING;
        const response = await createCheckoutSession(this.publisherData.id);
        if (!response)
          throw new Error(
            "There was a problem while initialising the checkout process."
          );
        console.log("response", response);
        const result = await stripe.redirectToCheckout({
          sessionId: response.session.id,
        });

        if (result.error) {
          throw result.error;
        }
        this.initCheckoutStatus = SUCCESS;
      } catch (error) {
        console.error(error);
        this.initCheckoutStatus = ERROR;
      }
    },
    loadStripe() {
      this.stripePromise = new Promise((resolve) => {
        requestAnimationFrame(() => {
          resolve(getStripe());
        });
      });
    },
  },
  mounted() {
    this.loadStripe();
  },
  created() {
    if (!this.publisherData.matchedCodesCount) {
      this.$router.push("/");
      return;
    }
  },
};
</script>

<style lang="scss" module>
.matchedCount {
  fill: #373a3c !important;
}
</style>